<template>
  <v-card :to="'/BlogPost/' + id" class="mx-auto" max-width="374">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      contain
      height="250"
      :src="require('@/assets/images/' + picturename)"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-card-title style="min-height: 128px">
      {{ title }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="4" xs="4" sm="6" md="4" lg="6" xl="6" align="center"
          ><v-rating
            :value="rating"
            color="primary"
            dense
            half-increments
            readonly
            :size="sizeFont(0)"
          ></v-rating
        ></v-col>
        <v-col
          cols="4"
          xs="4"
          sm="3"
          md="4"
          lg="3"
          xl="3"
          align="center"
          class="grey--text"
        >
          {{ Math.round(rating * 100) / 100 }}
        </v-col>
        <v-col
          cols="4"
          xs="4"
          sm="3"
          md="4"
          lg="3"
          xl="3"
          align="center"
          class="grey--text"
        >
          <v-icon color="primary">mdi-message</v-icon>
          {{ amountOfRatings }}
        </v-col>
      </v-row>

      <v-row :style="'min-height:' + sizeSpace(0) + 'px'">
        <v-chip
          class="ma-1"
          v-for="category in categories"
          :key="'category_' + category.NAME"
          small
        >
          {{ category.NAME }}
        </v-chip>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    categories: Array,
  }),

  props: {
    id: Number,
    title: String,
    rating: Number,
    amountOfRatings: Number,
    picturename: String,
  },

  mounted() {
    this.$store
      .dispatch("getBlogpostCategories", {
        ID: this.id,
      })
      .then((response) => {
        if (response.msg != null) {
          //TODO: Was tun wir wenn wir kein Element erhalten???
        } else {
          this.categories = response;
        }
      });
  },

  methods: {
    sizeFont(minusValue) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 14 - minusValue;
        case "sm":
          return 14 - minusValue;
        case "md":
          return 12 - minusValue;
        case "lg":
          return 14 - minusValue;
        case "xl":
          return 14 - minusValue;
        default:
          return 14 - minusValue;
      }
    },

    sizeSpace(minusValue) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0 - minusValue;
        case "sm":
          return 60 - minusValue;
        case "md":
          return 90 - minusValue;
        case "lg":
          return 65 - minusValue;
        case "xl":
          return 65 - minusValue;
        default:
          return 0 - minusValue;
      }
    },
  },
};
</script>

<style>
</style>