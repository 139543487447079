<template>
  <div class="blog">
    <v-row class="ma-3">
      <v-col
        v-for="blogpost in blogposts"
        :key="blogpost.ID"
        cols="12"
        xs="6"
        sm="6"
        md="3"
        lg="3"
        xl="2"
        justify="center"
      >
        <BlogProjectCard
          :id="blogpost.ID"
          :title="blogpost.TITLE"
          :rating="blogpost.RATING"
          :amountOfRatings="blogpost.AMOUNTOFCOMMENTS"
          :categories="blogpost.CATEGORIES"
          :picturename="blogpost.PICTURENAME"
        ></BlogProjectCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BlogProjectCard from "@/components/BlogProjectCard.vue";

export default {
  data: () => ({
    blogposts: [],
    categories: [],
  }),

  mounted() {
    this.getBlogposts();
  },

  methods: {
    getBlogposts() {
      this.$store.dispatch("getBlogposts").then((response) => {
        if (response.msg != null) {
          //TODO: Was tun wir wenn wir kein Element erhalten???
        } else {
          this.blogposts = response;

          //TODO: für später
          /*this.blogposts.forEach(
            blogpost => fetch(this.$path + "/categories/" + blogpost.ID)
              .then((response2) => response2.json())
              .then((result2) => {
                if (result2.msg != null) {
                  //TODO: Was tun wir wenn wir kein Element erhalten???
                } else {
                  this.categories[blogpost.ID] = result2;
                }
              })
            );*/
        }
      });
    },
  },

  components: {
    BlogProjectCard,
  },
};
</script>

<style>
</style>